



















































































































































































































































































.positionList {
    ::v-deep .ivu-input {
        height: 0.5rem !important;

        @media (max-width: 767px) {
            height: 0.5rem * 1.5 !important;
        }
    }

    .ivu-table td {
        height: 0.5rem;
    }

    .ivu-table-stripe .ivu-table-body tr:nth-child(2n) td,
    .ivu-table-stripe .ivu-table-fixed-body tr:nth-child(2n) td {
        background-color: #ffffff;
    }

    .ivu-table-header thead tr th {
        padding: 0.15rem 0;
        background-color: #ff1932;
        color: #ffffff;
    }

    .ivu-table-body table {
        border: 0.01rem solid #eeeeee;
    }
}
